.page_container {
    margin: auto;
}

.page_flex_container {
    width: 94%;
    margin: auto !important;
}

.back_button {
    width: 100%;
    min-width: 480px;
    text-align: left;
    padding: 16px 16px 16px 0px;
}

.info_container {
    display: flex;
    width: 100%;
    height: 100%;
}

.info_left {
    width: 65%;
    text-align: left;
    min-width: 480px;
}

.left_margin {
    padding: 16px;
}

.tooltip_container {
    position: relative;
    display: inline-block;
}

.tooltip_text {
    visibility: hidden;
    opacity: 0;
    width: auto;
    background-color: #1c233a;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 105%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: small;
    box-shadow: 2.9px 5.8px 5.8px hsl(0deg 0% 0% / 0.41);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.tooltip_container:hover .tooltip_text {
    visibility: visible;
    opacity: 1;
}

.launch_button {
    overflow: hidden;
    margin-right: 8px !important;
    cursor: pointer;
    max-width: 12em;
}

.launch_button_label {
    max-width: 6em;
    display: inline-block;
}


.metadata_title {
    margin-top: 2rem;
}

.app_validation_title {
    margin-top: 3rem;
}

.done_app_list {
    list-style: none;
}

.app_list {
    padding-left: 0;
    list-style: none;
    margin-left: 1em;
}

.app_list li {
    min-width: 80px;
    margin-bottom: 12px !important;
}

.validated_app_list li::before {
    content: '\2022';
    color: #2ed19b;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.rejected_app_list li::before {
    content: '\2022';
    color: #e93255;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.pending_app_list li::before {
    content: '\2022';
    color: #a2acbd;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.validate_reject_buttons {
    float: right;
    margin-top: 20px;
}

.info_right {
    min-width: 360px;
    margin-top: 4em;
    padding: 2em;
    width: 35%;
}

.comments_scroll {
    height: 480px !important;
    overflow-y: scroll !important;
}

.activities_comments {
    line-height: 1em;
    text-align: left;
}

.unavailable {
    border-radius: 4px;
    padding: 0em;
    margin-top: 0.5em;
    text-align: left;
}

.icon {
    margin: 1rem 1rem 1rem 0;
}

.available {
    background-color: #f0f4f6;
    border-radius: 4px;
    padding: 1em;
    margin-top: 0.6em;
    margin-right: 0.3rem;
    text-align: left;
}

.comment {
    margin: 0em;
    border-radius: 4px;
    padding: 1px;
}

.date_stamp {
    color: grey;
    margin: 0.3rem 0;
}

/* utils */
.grey_color {
    color: grey;
}

.navy_color {
    color: navy;
}

.no_margin {
    margin: 0 !important;
}

/* Works on Firefox */
.comments_scroll {
    scrollbar-width: thin;
    scrollbar-color: transparent silver;
}

/* Works on Chrome, Edge, and Safari */
.comments_scroll::-webkit-scrollbar {
    width: 8px;
}

.comments_scroll::-webkit-scrollbar-track {
    background: transparent;
}

.comments_scroll::-webkit-scrollbar-thumb {
    background-color: silver;
    border-radius: 20px;
    border: 3px solid silver;
}
